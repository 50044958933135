import css from 'styled-jsx/css'

export const HRGlossaryDetailStyle = css.global`
  body {
    background-color: #fff;
  }
  .detail-heading {
    background-color: #eff7ff;
    padding: 24px 0;
  }
  .detail-breadcrumb {
    text-align: center;
    margin-bottom: 16px;
  }
  .detail-breadcrumb span:last-child .ant-breadcrumb-separator {
    display: none;
  }
  .detail-breadcrumb .ant-breadcrumb-link {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  .detail-breadcrumb span.ant-breadcrumb-link {
    color: #4f535b;
  }

  .hr-wrapper {
    max-width: 792px;
    margin: 0 auto;
  }

  .detail__title {
    font-size: 36px;
    line-height: 44px;
    color: #141921;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
  }
  .detail__summary {
    font-size: 18px;
    line-height: 26px;
    color: #141921;
    margin-bottom: 24px;
    text-align: center;
  }

  .detail-content {
    position: relative;
    padding: 24px 0 80px;
    font-size: 18px;
    line-height: 24px;
    color: #4f535b;
  }
  .detail__button-back.ant-btn {
    margin-top: 40px;
    font-size: 14px;
    line-height: 40px;
    font-weight: 500;
    background-color: #fff;
    border: 1px solid #d1d7dc;
    padding: 1px 28px;
  }

  .glossary-l4j {
    max-width: 1200px;
    background-image: url('/img/looking_for_job.png');
    background-size: 1200px 264px;
    padding: 47px 102px 70px;
    margin: 0 auto 100px;
  }
  .glossary-l4j__title {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.32px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 8px;
  }
  .glossary-l4j__summary {
    font-size: 18px;
    line-height: 26px;
    color: #fff;
    margin-bottom: 24px;
  }
  .glossary-l4j__footer .ant-btn {
    height: 48px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 12px 32px;
    margin-right: 16px;

    background-color: #40a9ff;
    border-color: #ffffff;
    color: #ffffff;
  }
  .glossary-l4j__footer .ant-btn-primary {
    background-color: #fff;
    border-color: #fff;
    color: #1890ff;
  }

  .share-wrapper {
    position: fixed;
    right: 12.6%;
    top: calc(50vh - 10px);
    display: flex;
    flex-direction: column;
    z-index: 10;
  }
  .share-wrapper .ant-btn {
    margin-bottom: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 576px) {
    .detail-content {
      padding: 24px 0 40px;
    }
    .detail-heading {
      padding: 24px 16px;
    }
    .detail__title {
      font-size: 24px;
      line-height: 32px;
    }

    .hr-wrapper {
      padding: 0 16px;
    }
    .detail__button-back.ant-btn {
      margin-top: 24px;
      margin-bottom: 40px;
    }

    .share-wrapper {
      position: relative;
      right: unset;
      top: unset;
      flex-direction: row;
      align-items: center;
      margin-top: 24px;
    }
    .share-wrapper .ant-btn {
      margin-right: 16px;
      margin-bottom: 0;
    }

    .glossary-l4j {
      background-image: url('/img/looking_for_job.mobile.png');
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: top left;
      padding: 48px 16px 145px;
      margin-bottom: 80px;
    }
    .glossary-l4j__title {
      font-size: 28px;
      line-height: 32px;
    }
    .glossary-l4j__footer {
      flex-wrap: wrap;
    }
    .glossary-l4j__footer .ant-btn-primary {
      margin-bottom: 16px;
    }
  }
`
