import HRGlossaryDetailPage from '../hr-glossary-detail'
import HRGlossaryPage from 'src/@legacy/wrapper/hr-glossary'
import { GlossaryType } from '../hr-glossary/components/HRGlossaryWrapper'
import { FC } from 'react'

export const HRGlossaryRouting: FC<{ glossaryDetail: GlossaryType; glossaryData: GlossaryType[] }> = ({ glossaryDetail, glossaryData }) => {
  return glossaryDetail ? (
    <HRGlossaryDetailPage glossaryDetail={glossaryDetail} />
  ) : glossaryData?.length > 0 ? (
    <HRGlossaryPage glossaryData={glossaryData} />
  ) : null
}
