import css from 'styled-jsx/css'

export const HRGlossaryStyle = css.global`
  .company-layout .container {
    background-color: #fff;
  }
  .banner {
    background-image: url('/img/hero_image.png');
    background-size: 1920px 260px;
    background-position: center;
    text-align: center;
    padding: 56px 0 110px;
  }
  .wrapper-container {
    max-width: 1230px !important;
  }

  .banner__title {
    font-size: 48px;
    letter-spacing: 0.48px;
    line-height: 56px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 12px;
  }

  .banner__summary {
    font-size: 18px;
    line-height: 26px;
    color: #fff;
  }

  .justify-content-between {
    justify-content: space-between;
  }
  .content-center {
    justify-content: center;
  }

  .glossary-result {
    margin-top: 64px;
  }
  .glossary-result__title {
    font-size: 28px;
    line-height: 36px;
    color: #141921;
    font-weight: bold;
    padding-bottom: 23px;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 8px;
  }
  .grid-contain {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 56px;
    margin-right: 32px;
  }
  .glossary-result__item {
    margin-top: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
    line-height: 24px;
  }
  .glossary-wrapper {
    padding-bottom: 100px;
  }

  .glossary-wrapper .empty-wrapper {
    padding-top: 64px;
    padding-bottom: 0;
  }

  @media only screen and (max-width: 576px) {
    .glossary-wrapper {
      padding-bottom: 72px;
    }
    .banner {
      background-image: url('/img/hero_image_mobile.webp');
      background-size: 100% auto;
      background-position: center;
      padding: 30px 16px 96px;
    }
    .banner__title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 16px;
    }
    .banner__summary {
      font-size: 16px;
      line-height: 24px;
    }

    .glossary-result {
      margin-top: 40px;
      margin-bottom: 8px;
    }
    .grid-contain {
      display: block;
    }
    .glossary-result__title {
      font-size: 18px;
      line-height: 24px;
      padding-bottom: 15px;
      margin-bottom: 0;
    }
    .glossary-result__item {
      margin-top: 16px;
      margin-bottom: 4px;
    }
  }
`
