import withAppProvider from 'src/@legacy/layout/hoc/withAppProvider'
import withHeaderDynamic from 'src/@legacy/layout/hoc/withHeaderDynamic'
// import HRGlossaryDetailPage from 'src/@legacy/wrapper/hr-glossary-detail'
import Axios from 'axios'
import { API_URL_HR_GLOSSARY_LIST } from 'src/@legacy/@core/utilities/APIConstant'
import { HRGlossaryRouting } from 'src/@legacy/wrapper/hr-glossary-routing'

const fetchJobDetail = (filter: string) => Axios.get(`${API_URL_HR_GLOSSARY_LIST}${filter}`, {}).then((rs) => rs.data.data[0])
export const fetchGlossaryList = (queryString?: string) =>
  Axios.get(
    `${API_URL_HR_GLOSSARY_LIST}?sort=title_article&filter[_and][0][_and][0][status][_eq]=published${queryString ? `${queryString}` : ''}`,
    {}
  ).then((rs) => {
    const data = rs.data.data.map((item) => ({
      ...item,
      ...{
        char: item?.title_article.charAt(0)?.toLowerCase().match(/[a-z]/i) ? item.title_article.charAt(0) : '#'
      }
    }))

    return data
  })
export async function getServerSideProps({ query }) {
  const { companyID } = query
  if (companyID === 'thuat-ngu-nganh-nhan-su') {
    const data = await fetchGlossaryList()

    // Pass data to the page via props
    return { props: { glossaryData: data } }
  } else {
    const filter = `?filter={"slug": "${companyID}"}`

    // Fetch data from external API
    const data = await fetchJobDetail(filter)

    // Pass data to the page via props
    return { props: { glossaryDetail: data } }
  }
}

export default withAppProvider(withHeaderDynamic(HRGlossaryRouting))
